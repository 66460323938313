.ant-page-header-back-button {
  border: 0;
  background: transparent;
  padding: 0;
  line-height: inherit;
  display: inline-block;
}

nz-page-header,
nz-page-header-content,
nz-page-header-footer {
  display: block;
}
