@import "theme";

.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.no-warp {
  white-space: nowrap;
}

.text-center {
  text-align: center;
}

.cursor-pointer {
  cursor: pointer;
}

.no-data, .no-share {
  width: 80%;
  margin: 50px auto;
  text-align: center;
  border: 1px dashed #bcbcbc;
  line-height: 40px;
  overflow: hidden;

  > span {
    color: @primary-color;
  }
}

.out-of-service {
  &::after {
    content: "";
    background-image: url("images/out-of-service.png");
    width: 24px;
    height: 20px;
    display: block;
  }
}

//表单按钮loading效果
.ball-clip-rotate {
  & > div {
    width: 18px;
    height: 18px;
    border-radius: 100%;
    margin-bottom: -4px;
    border: 2px solid #939395;
    border-bottom-color: transparent;
    display: inline-block;
    animation: rotate 0.75s 0s linear infinite;
    animation-fill-mode: both;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg) scale(1);
    }

    50% {
      transform: rotate(180deg) scale(0.6);
    }

    100% {
      transform: rotate(360deg) scale(1);
    }
  }
}

.loader {
  @keyframes loading {
    0% {
      box-shadow: 24px 0 0 0 rgba(204, 204, 204, 0.2), 48px 0 0 0 rgba(204, 204, 204, 0.2);
    }

    25% {
      box-shadow: 24px 0 0 0 rgba(204, 204, 204, 0.6), 48px 0 0 0 rgba(204, 204, 204, 0.6);
    }

    75% {
      box-shadow: 24px 0 0 0 #ccc, 48px 0 0 0 #ccc;
    }
  }

  width: 12px;
  height: 12px;
  background-color: #ccc;
  border-radius: 50%;
  animation: loading 1s linear infinite alternate;
}

.marker-name {
  border-radius: 5px;
  border: 1px solid #1b1b1b;
  padding: 2px;
  background-color: #1b1b1b;
  max-width: 300px;
  opacity: 0.7;
  color: #fff;
  display: flex;
  align-items: center;
}

.cus-marker-label {
  border-radius: 5px;
  // border: 1px solid #1b1b1b39;
  padding: 2px 4px;
  // background-color: #1b1b1b00;
  display: flex;
  align-items: center;
}

.primary-color {
  color: @primary-color;
}

.event-geofence {
  background-color: #359c62 !important;
}

.event-accelerate {
  background-color: #f9a01b !important;
}

.event-brake {
  background-color: #f9a01b !important;
}

.event-poi {
  background-color: #eb0b68 !important;
}

.event-stop {
  background-color: #dc5f4a !important;
}

.event-sos {
  background-color: #dc262b !important;
}

// 横向-<方向>
.row-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.row-left {
  display: flex;
  justify-content: left;
  align-items: center;
}

.row-right {
  display: flex;
  justify-content: right;
  align-items: center;
}

// 纵向垂直居中-<方向>
.column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.column-left {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.column-right {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;
}

.icon-font-17 {
  font-size: 17px !important;
}

.icon-font-20 {
  font-size: 20px !important;
}

.icon-font-32 {
  font-size: 32px !important;
}
