.ant-menu-submenu.ant-menu-submenu-placement-bottom {
  top: 6px;
  position: relative;
}

.ant-menu-submenu.ant-menu-submenu-placement-right {
  left: 4px;
  position: relative;

  &.ant-menu-submenu-rtl {
    left: auto;
    right: 4px;
  }
}

.ant-menu-submenu.ant-menu-submenu-placement-left {
  right: 4px;
  position: relative;

  &.ant-menu-submenu-rtl {
    right: auto;
    left: 4px;
  }
}
