nz-content {
  display: block;
}

nz-footer {
  display: block;
}

nz-header {
  display: block;
}
