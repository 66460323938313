filter-group, .filter-group {
  flex: 0 0 auto;
  display: flex;
  flex-wrap: wrap;

  .filter-item {
    display: flex;
    align-items: center;
    margin-right: 10px;
    margin-bottom: 10px;

    > label {
      min-width: 60px;
      text-align: right;
      margin-right: 10px;
    }

    .filter-value {
      width: 220px !important;
    }
  }
}
