@import "../../../core/styles/theme";

.dot {
  display: block;
  width: 10px;
  height: 10px;
  position: absolute;
  margin: 6px -17px;
  background-image: url('/assets/images/maintenance/dot.png');
  color: @primary-color;
}

.icon-color {
  color: #999;
}

.form-content {
  padding: 0 15px;
}

.maintenance-status {
  min-height: 122px;
  border: 1px dashed #ccc;
  background-color: #f9fafb;
  padding: 10px 35px 25px;

  &:hover {
    border: 1px solid #18aff3;
  }
}

.message {
  width: 100%;
  height: 90px;
  resize: none;
  padding: 10px;
  border: 1px solid #ccc;
}
