@import "../../../core/styles/theme";

.ant-btn {
  min-width: 80px;
  padding: 0 5px;
}

.ant-btn-cancel {
  color: #fff;
  background-color: #a5a6a4;
  border-color: #a5a6a4;

  &:hover, &:active, &:focus, &:link, &:visited {
    color: #fff;
    background-color: #ccc;
    border-color: #ccc;
  }
}

.ant-btn-handle {
  height: 28px;
  min-width: 0;
  color: #6c6c6c;
  background-image: linear-gradient(180deg, #f8f8f8, #efefef);
  border-color: #ccc;

  &:hover, &:active, &:focus, &:link, &:visited {
    color: #6c6c6c;
    background: #fff;
    border-color: #ccc;
  }
}

.icon-btn {
  position: absolute;
  right: 0;
  width: 32px;
  height: 33px;
  margin-top: -4px;
  color: #999;
  font-size: 18px;
  line-height: 36px;
  text-align: center;
  background-color: #eaecef;
  border-left: 1px solid #ccc;
  cursor: pointer;

  &:hover {
    color: @primary-color;
  }
}
