
.breadcrumb {
  padding: 4px 15px;
  background-color: #F3F3F5;
  border-bottom: 1px solid #e2e2e2;
  margin-bottom: 0;
  font-size: 14px;
  border-radius: 0;

  li {
    color: #333;
    display: inline-block;
    font-weight: bold;

    &.active {
      color: #333;
      font-weight: normal;
    }
  }

  li + li::before {
    color: #333;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    -moz-osx-font-smoothing: grayscale;
    content: "/";
    padding: 0 10px;
    transform: rotate(180deg);
  }
}
