nz-carousel {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.slick-dots {
  display: block;
}

.slick-track {
  opacity: 1;
}

.slick-list {
  direction: ltr;
}
