@import "../../styles/theme";

nz-radio-group.all-width {
  width: 100%;

  label {
    width: 50%;
    height: 35px;
    text-align: center;
    border-color: @primary-color !important;
  }
}
