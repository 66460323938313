.ant-menu-submenu-placement-bottomLeft {
  top: 3px !important;
}

.ant-menu-vertical .ant-menu-item {
  height: 32px;
}

.ant-menu-vertical .ant-menu-item:not(:last-child) {
  margin: 0;
}

.ant-dropdown {
  margin-top: 0 !important;
}

.nb-nav {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #e1e1e1;

  .nav-item {
    flex: 0 0 120px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    color: #333;
    background: #f0f0f0;
    border-right: 1px solid #e1e1e1;

    &:last-child {
      border-right: 0;
    }

    &.active, &:hover {
      color: #fff;
      background: @primary-color;
    }
  }
}
