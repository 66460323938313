@2RowsMargin: 10px 20px 20px  0;
@1RowsMargin: 10px 20px 0px  0;

.time-line {
  flex: auto;
  width: 600px;
  align-self: center;
  display: flex;
  flex-direction: column;

  .tracker-avatar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 0 0 50px;
    overflow: hidden;
    margin-top: 10px;
    margin-left: 115px;
    width: 475px;

    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
  }

  .time-line-list {
    // 0px兼容ie11和edge
    flex: 1 0 0px;
    overflow: auto;
    padding-right: 10px;

    .list-item {
      display: flex;
    }

    .time-line-date {
      flex: 0 0 110px;
      text-align: right;
      margin: 10px 30px 0px  0px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      span {
        display: block;
      }
    }

    .time-line-detail {
      flex: auto;
      padding: 10px 0 0 40px;
      border-left: 1px solid #ddd;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }

    .detail-stop {
      position: relative;
      padding: 5px;
      border: 1px solid #e7e7e7;

      &.chosen {
        border: 1px solid #e85e48;
      }

      .stop-time {
        font-weight: bold;

        span {
          color: @primary-color;
        }
      }

      &::after {
        content: '';
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 8px 9px 8px 0;
        border-color: transparent #fff transparent transparent;
        position: absolute;
        top: 50%;
        left: -9px;
        margin-top: -8px;
      }

      &::before {
        content: '';
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 9px 10px 9px 0;
        border-color: transparent #e7e7e7 transparent transparent;
        position: absolute;
        top: 50%;
        left: -10px;
        margin-top: -9px;
      }
    }

    .detail-traveled {
      line-height: 25px;
      padding: 0 10px;
      background: #ebedef;
      border: 1px solid #e7e7e7;
      border-radius: 1px;
      margin-bottom: 10px;
    }

    .event-size {
      font-size: 12px;
    }

    .time-line-event,.time-line-trip {
      display: inline-block;
      width: 30px;
      height: 30px;
      line-height: 30px;
      color: #fff;
      border: 0;
      background: #e85e48;
      text-align: center;
      font-style: normal;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: -56px;
      margin-top: -15px;
    }

    .time-line-trip {
      background: #acd46e;
    }

    .date-20-mb {
      margin: @2RowsMargin;
    }

    .date-10-mb {
      margin: @1RowsMargin;
    }

    .time-line-start, .time-line-end {
      width: 15px;
      height: 15px;
      line-height: 15px;
      left: -49px;
      margin-top: -8px;
      border: 1px solid #f9a01b;
      background: white;
    }

    .video-channel, .photos-display {
      display: flex;
      margin-top: 5px;

      .video-tag, .photo-tag {
        cursor: pointer;
      }
    }
  }

  .time-line-total {
    flex: 0 0 auto;
    margin: 10px 0 0 190px;

    .total-item {
      line-height: 25px;

      span {
        font-size: 18px;
        color: @primary-color;
      }
    }
  }
}
