textarea.nz-textarea-autosize-measuring {
  height: auto !important;
  overflow: hidden !important;
  // Having 2px top and bottom padding seems to fix a bug where Chrome gets an incorrect
  // measurement. We just have to account for it later and subtract it off the final result.
  padding: 2px 0 !important;
  box-sizing: content-box !important;
}

.@{search-prefix} {
  &-enter-button {
    & + .@{ant-prefix}-input-group-addon,
    input + .@{ant-prefix}-input-group-addon {
      .@{search-rtl-cls}& {
        .@{search-prefix}-button.@{ant-prefix}-btn-icon-only {
          .square(@btn-height-base);

          &.@{ant-prefix}-btn-sm{
            .square(@btn-height-sm);
          }
          &.@{ant-prefix}-btn-lg{
            .square(@btn-height-lg);
          }
        }
      }
    }
  }
}
