html {
  height: 100%;
  font-size: 14px;

  body {
    height: 100%;
    position: relative;
    min-height: 600px;
    min-width: 1200px;
    padding-right: 0 !important;
  }

  input, button {
    outline: none;
  }
}

@media print {
  html body {
    min-width: auto;
    min-height: auto;
    width: 1000px;
    height: auto;
  }
}

li {
  list-style: none;
  text-decoration: none;
}

ul, h4 {
  margin: 0;
  padding: 0;
}

:focus {
  outline: none;
}

iframe {
  border-width: 0;
}

a:focus {
  text-decoration: none;
}
