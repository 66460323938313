@viewport {
  zoom: 1.0;
  width: ~'device-width';
}

@import "reset";
@import "common";
@import "mixins";
@import "theme";
@import "components/index";
@import "player";

@media print {
  .no-print {
    display: none !important;
  }
}
