.ant-badge {
  .ant-scroll-number:only-child {
    position: relative;
    top: auto;
    display: block;
  }

  .ant-badge-count:only-child {
    transform: none;
  }
}

nz-ribbon {
  display: block;
}