// inline mode
.@{picker-prefix-cls}-inline {
  border: none;
  padding: 0;
  .@{picker-prefix-cls} {
    &-range-arrow {
      display: none !important;
    }
  }
}
