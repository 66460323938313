.marker-status {
  background-image: url("/assets/images/tracker-icon/marker-icons.png");
  width: 37px;
  height: 40px;
}

.tracker-move {
  background-position: -74px 0;
}

.tracker-stop {
  background-position: 0 0;
}

.tracker-stop-idle {

}

.tracker-offline {
  background-position: -37px 0;
}

.tracker-slow-speed {
  background-position: -111px 0;
}

.marker-speed {
  background-image: url("/assets/images/tracker-icon/speed.png");
  width: 40px;
  height: 40px;
  background-size: 80px auto;
  transform: rotate(180deg);
}

.tracker-normal {
  background-position: -40px 0;
}
