@import "../../../core/styles/theme";
@header-bg: linear-gradient(180deg, #fefefe, #efefef);

.nb-table-container {
  flex: auto;
  display: flex;
  flex-direction: column;

  .table-content, .chart-content {
    flex: auto;
    display: flex;
    flex-direction: column;

    nz-table {
      flex: auto;
    }
  }

  .table-content {
    .table-title {
      border-bottom: 2px solid #f0f0f0;
      line-height: 38px;
      font-weight: bold;

      .icon {
        display: inline-block;
        padding: 0 20px;
        margin-right: 20px;
        border-right: 1px solid @border-color-split;

        .iconfont {
          font-weight: 100;
          font-size: 17px;
          text-shadow: 1px 1px #fff;
          color: #898989;
          vertical-align: middle;
        }
      }

      .title {
        display: inline-block;
        margin-right: 40px;
      }
    }

    .ant-table {
      font-size: 12px;
      font-family: Open Sans, Arial, Helvetica, sans-serif;
      border-collapse: collapse;
      border-spacing: 0;
    }

    .ant-table-body {
      //overflow: auto;
    }

    .ant-table-thead > tr > th {
      // 设置 font-family 可以解决字体(和根字体样式相同) font-weight 无效的问题 (详情:https://segmentfault.com/a/1190000007787731)
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
      font-size: 14px;
      color: #999;
      font-weight: 500;
      background: #fff;
      border-bottom: 1px solid #f0f0f0;
    }

    table {
      border-bottom: 0;
    }

    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      text-align: center;
      padding: 5px;
      line-height: 25px;
      vertical-align: middle;
      word-break: initial;
      // 消除组件默认边框
      border-right: 0 !important;
      &.nb-td {
        padding: 0;

        & > span {
          display: block;
          white-space: nowrap;
          padding: 5px;
          border-bottom: 1px solid @border-color-split;

          &:last-child {
            border-bottom: 0;
          }
        }
      }

      .iconfont {
        font-size: 17px;
        color: #999;
        cursor: pointer;
      }
    }
  }
}

@media print {
  .table-handler {
    visibility: hidden;
  }
}
// 修改 camera/video 中样式
.ant-table-thead > tr > th {
  background-color: #fff;
  color: #999;
}
